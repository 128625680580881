import React from 'react';
import http from 'stream-http';
import isPropValid from '@emotion/is-prop-valid';
import { Script } from 'gatsby';

import GraphqlProvider from './src/context/GraphqlProvider';
import AnalyticsProvider from './src/context/AnalyticsProvider';
import AuthProvider from './src/context/AuthProvider';
import ThemeProvider from './src/context/ThemeProvider';
import PopupProvider from './src/context/PopupProvider';

if (typeof window !== 'undefined') {
	global.http = http;
}

export const wrapPageElement = ({ element, ...props }) => {
	if (props?.props?.location?.pathname?.includes('redirect')) return element;

	const isBrowser = typeof window !== 'undefined';

	return (
		<>
			<Script id="get-ip-script">
				{`function getIP(json) {sessionStorage.setItem('nte-ip', json.ip);}`}
			</Script>
			<Script
				type="application/javascript"
				src="https://api.ipify.org?format=jsonp&callback=getIP"
			/>
			<Script
				key="CookieConsent"
				id="CookieConsent"
				src="https://policy.app.cookieinformation.com/uc.js"
				data-culture="NB"
				type="text/javascript"
				onLoad={() => {
					window.dispatchEvent(new Event('CookieInformationLoaded'));
				}}
			/>

			<GraphqlProvider>
				<ThemeProvider>
					<AnalyticsProvider>
						<AuthProvider isBrowser={isBrowser}>
							<PopupProvider>{element}</PopupProvider>
						</AuthProvider>
					</AnalyticsProvider>
				</ThemeProvider>
			</GraphqlProvider>
		</>
	);
};

// This implements the default behavior from styled-components v5
export function shouldForwardProp(propName, target) {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
}
