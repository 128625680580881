import { css } from 'styled-components';

const Theme = {
	colors: {
		// Blå
		blue100: '#F0F8FC',
		blue200: '#E5F3F9',
		blue300: '#CEECF9',
		blue400: '#82CEF0',
		blue500: '#17A4D8',
		blue600: '#0079AD', // Primær-blå
		blue700: '#056089',
		blue800: '#094462',
		blue900: '#081E29',

		// Grønn
		green100: '#F3F9F3',
		green200: '#E8F3E8',
		green300: '#D4E8D7',
		green400: '#A6D5AE',
		green500: '#7DC189', // Primær-grønn
		green600: '#5BA070',
		green700: '#397F57',
		green800: '#295334',
		green900: '#244030',

		// Coral
		coral100: '#FEEEEA',
		coral200: '#FADCD5',
		coral300: '#F7C2B5', // Primær-korall
		coral400: '#F5AD9C',
		coral500: '#F29780',
		coral600: '#DF7565',
		coral700: '#C84E46',
		coral800: '#B32727',
		coral900: '#811717',

		// Grå
		grey100: '#', // Missing
		grey200: '#F7F7F7',
		grey300: '#EDEDED',
		grey400: '#D6D6D6',
		grey500: '#B5B7BB', // Disabled
		grey600: '#', // Missing
		grey700: '#71767E',
		grey800: '#505359',
		grey900: '#28292C',

		// Misc
		white: '#ffffff',
		black: '#28292c',
		yellow: '#f7ca2d',
		seaGreen: '#007585',
		fjuttGreen: '#7cc189',
		fjuttGreenLight: '#e9f3e9',

		// Auth
		vipps: '#ff5b24',
		vippsDarker: '#db460f',
		bankId: '#39134c',
		bankIdDarker: '#270d35',
	},

	media: {
		XSmallOnly: content =>
			css`
				@media only screen and (max-width: 540px) {
					${content}
				}
			`,
		smallUp: content =>
			css`
				@media only screen and (min-width: 640px) {
					${content}
				}
			`,
		smallOnly: content =>
			css`
				@media only screen and (max-width: 766px) {
					${content}
				}
			`,
		medium: content =>
			css`
				@media only screen and (min-width: 767px) {
					${content}
				}
			`,
		large: content =>
			css`
				@media only screen and (min-width: 1100px) {
					${content}
				}
			`,
		xLarge: content =>
			css`
				@media only screen and (min-width: 1800px) {
					${content}
				}
			`,
		xLargeDown: content =>
			css`
				@media only screen and (max-width: 1799px) {
					${content}
				}
			`,
		mediumDown: content =>
			css`
				@media only screen and (max-width: 1099px) {
					${content}
				}
			`,
		mediumOnly: content =>
			css`
				@media (max-width: 1099px) and (min-width: 767px) {
					${content}
				}
			`,
		tabletOnly: content =>
			css`
				@media (min-width: 1100px) and (max-width: 1380px) {
					${content}
				}
			`,
	},

	widths: {
		default: '1240px',
		medium: '828px',
		small: '600px',
		xsmall: '400px',
		large: '1240px',
		xlarge: '1420px',
		giga: '1600px',
		full: '100%',
	},

	gutters: {
		none: '0',
		auto: 'auto',
		xsmall: '20px',
		small: '40px',
		medium: '60px',
		large: '75px',
		xlarge: '120px',
	},

	spacing: {
		desktop: {
			none: '0px',
			xxsmall: '10px',
			xsmall: '20px',
			small: '30px',
			medium: '45px',
			large: '90px',
			xlarge: '150px',
		},
		tablet: {
			none: '0px',
			xxsmall: '10px',
			xsmall: '15px',
			small: '30px',
			medium: '40px',
			large: '60px',
			xlarge: '100px',
		},
		mobile: {
			none: '0px',
			xxsmall: '5px',
			xsmall: '10px',
			small: '20px',
			medium: '30px',
			large: '40px',
			xlarge: '50px',
		},
	},

	typography: {
		body: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '17px',
			lineHeight: '29px',
			fontWeight: 'normal',
		},
		headings: {
			lineHeight: '1.3',
			fontWeight: '500',
		},
		helpText: {
			fontSize: '14px',
			lineHeight: '22px',
			color: '#B5B7BB',
		},
	},

	forms: {
		input: {
			padding: '15px 15px 15px 20px',
			height: '56px',
		},
	},

	utils: {
		borderRadius: '5px',
		borderRadiusLarge: '15px',
		borderRadiusSmall: '3px',
		boxShadow: '0px 3px 6px 2px rgba(58, 58, 58, 0.15)',
		boxShadowSoft: '0px 2px 4px rgba(58, 58, 58, 0.15)',
		boxShadowHard: '0px 2px 6px rgba(58, 58, 58, 0.2)',
		boxShadowBig: '0px 3px 10px 0px rgba(58, 58, 58, 0.15)',
	},
};

export default Theme;
