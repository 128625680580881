import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faTriangleExclamation as faTriangleExclamationRegular } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';

export default function IconWarning({ type = 'solid', ...props }) {
	return (
		<FontAwesomeIcon
			icon={
				type === 'solid'
					? faTriangleExclamation
					: faTriangleExclamationRegular
			}
			{...props}
		/>
	);
}
