import React from 'react';
import styled, { css } from 'styled-components';

const Wrap = styled.div`
	padding-top: ${p => p.theme.spacing.desktop[p.$sizes.desktop.top]};
	padding-bottom: ${p => p.theme.spacing.desktop[p.$sizes.desktop.bottom]};
	box-sizing: border-box;
	${p =>
		p.theme.media.mediumDown(css`
			padding-top: ${p => p.theme.spacing.tablet[p.$sizes.tablet.top]};
			padding-bottom: ${p =>
				p.theme.spacing.tablet[p.$sizes.tablet.bottom]};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding-top: ${p => p.theme.spacing.mobile[p.$sizes.mobile.top]};
			padding-bottom: ${p =>
				p.theme.spacing.mobile[p.$sizes.mobile.bottom]};
		`)}

	// If the component is first and has no background color, add margin-top
	${p =>
		!!p.$marginTop &&
		css`
			margin-top: ${p => p.theme.spacing.desktop.small};
			${p =>
				p.theme.media.mediumDown(css`
					margin-top: ${p => p.theme.spacing.tablet.small};
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					margin-top: ${p => p.theme.spacing.mobile.small};
				`)}
		`}
`;

/**
 * A React component that provides spacing for its children.
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} [props.spacing={ top: 'none', bottom: 'xlarge' }] - The spacing around the component. Defaults to { top: 'none', bottom: 'xlarge' }.
 * @param {ReactNode} props.children - The children of the component.
 * @param {Object} props.props - Additional properties.
 *
 * @returns {ReactElement} A `Wrap` component with the calculated spacing and the provided children.
 */
export default function Spacing({
	spacing = {
		top: 'none',
		bottom: 'xlarge',
	},
	children,
	bgColor = '',
	componentindex = undefined,
	__typename = '',
	...props
}) {
	// Components that are considered hero components, and should not have top spacing
	const heroComponents = [
		'ContentfulKomponentToppbanner',
		'ContentfulKomponentScrollytelling',
		'ContentfulKomponentSkreddersydd',
	];

	// Check if the background color is set to 'Transparent' or not set
	const noBgColor = !bgColor || bgColor === 'Transparent';

	// Generate sizes for the component
	const sizes = generateSizes({
		top: spacing?.top,
		bottom: spacing?.bottom,
		heroComponents,
		noBgColor,
		isTopBanner:
			componentindex === 0 && heroComponents?.includes(__typename),
		componentindex,
	});

	return (
		<Wrap
			id={props?.id || ''}
			className={`spacing ${props?.className || ''}`}
			data-cy={props['data-cy'] || ''}
			$marginTop={props?.componentindex === 0 && noBgColor}
			$sizes={sizes}>
			{children}
		</Wrap>
	);
}

/**
 * Generates size values for different screen sizes based on the provided parameters.
 *
 * @param {Object} params - The parameters for generating sizes.
 * @param {string} params.top - The top size value.
 * @param {string} params.bottom - The bottom size value.
 * @param {Object} params.props - Additional properties.
 * @param {number} params.props.componentindex - The index of the current element.
 * @param {string} params.props.__typename - The type of the current element.
 * @param {string} params.props.tabletTop - The top size value for tablets.
 * @param {string} params.props.tabletBottom - The bottom size value for tablets.
 * @param {string} params.props.mobileTop - The top size value for mobiles.
 * @param {string} params.props.mobileBottom - The bottom size value for mobiles.
 * @param {string} params.props.className - The class name for the current element.
 * @returns {Object} An object containing the generated sizes for desktop, tablet, and mobile screens.
 * Each size object contains 'top' and 'bottom' properties.
 */
function generateSizes({ top, bottom, ...props }) {
	// If the component is the first one and not a top banner, set top to large
	if (!props?.isTopBanner && props?.componentindex === 0) {
		top = 'large';
	}

	return {
		desktop: {
			top: (props?.isTopBanner && 'none') || top,
			bottom: bottom,
		},
		tablet: {
			top: (props?.isTopBanner && 'none') || props?.tabletTop || top,
			bottom: props?.tabletBottom || bottom,
		},
		mobile: {
			top: (props?.isTopBanner && 'none') || props?.mobileTop || top,
			bottom: props?.mobileBottom || bottom,
		},
	};
}

/**
 * Converts a Norwegian spacing descriptor into its English equivalent.
 *
 * @param {string} value - The Norwegian spacing descriptor.
 *   Expected values are 'Ingen', 'Liten', 'Medium', 'Stor', 'Ekstra stor'.
 * @returns {string} The English equivalent of the input spacing descriptor.
 *   Possible return values are 'none', 'small', 'medium', 'large', 'xlarge'.
 *   If the input value does not match any of the expected inputs, 'none' is returned.
 */
export function getSpacingValue(value) {
	let options = {
		Ingen: 'none',
		Liten: 'small',
		Medium: 'medium',
		Stor: 'large',
		'Ekstra stor': 'xlarge',
	};
	return options[value] || options['Ingen'];
}

/**
 * Checks if the spacing values are set to 'none'.
 * @param {Object} props - The properties to check.
 * @param {string} props.top - The top spacing value.
 * @param {string} props.bottom - The bottom spacing value.
 * @returns {boolean} True if both top and bottom spacing values are set to 'none', false otherwise.
 */
export function isNoSpacing(props) {
	if (!props || !Object.keys(props).length > 0) return false;

	// If top and bottom is set to 'none' then return true
	return props?.top === 'none' && props?.bottom === 'none';
}
