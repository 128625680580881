import React from 'react';
import styled, { css } from 'styled-components';

import { showContent } from 'libs/animations';
import Altibox from './vi-leverer-altibox.inline.svg';
import ScreenReaderText from 'components/screen-reader-text';

const Wrap = styled.div`
	z-index: 5;
	display: flex;
	align-items: center;

	${p =>
		p.$animate &&
		css`
			animation-name: ${showContent};
			animation-delay: 1.25s;
			animation-duration: 2s;
			animation-fill-mode: both;
		`}

	${p =>
		p.centered &&
		css`
			justify-content: center;
		`};

	${p =>
		p.variant === 'product-columns' &&
		css`
			min-width: 220px;
			${p =>
				p.theme.media.medium(css`
					padding: 0 0 0 ${p => p.theme.spacing.desktop.xsmall};
					justify-content: end;
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					justify-content: center;
					margin: 0 0 ${p => p.theme.spacing.desktop.xsmall} !important;
				`)}
		`};

	${p =>
		p.theme.media.smallOnly(css`
			text-align: center;
			margin: ${p => p.theme.spacing.desktop.xxsmall} 0;
		`)}
	svg {
		margin-left: ${p => p.theme.spacing.desktop.xxsmall};
	}

	span {
		font-weight: 600;
		font-size: 17px;
		line-height: 29px;
		text-transform: uppercase;
		color: ${p =>
			p.color === 'black' ? p.theme.colors.grey900 : 'white'} !important;
	}
`;

export default function AltiboxLogo({
	animate = false,
	color = 'black',
	...props
}) {
	return (
		<Wrap $animate={animate} color={color} {...props}>
			<ScreenReaderText>Vi leverer Altibox</ScreenReaderText>
			<span>Vi leverer</span>
			<Altibox aria-label="Vi leverer Altibox" width="77" height="28" />
		</Wrap>
	);
}
